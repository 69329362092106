import { render, staticRenderFns } from "./PsychologIsntAuth.vue?vue&type=template&id=6d4ff467&scoped=true&"
import script from "./PsychologIsntAuth.vue?vue&type=script&lang=js&"
export * from "./PsychologIsntAuth.vue?vue&type=script&lang=js&"
import style0 from "./PsychologIsntAuth.vue?vue&type=style&index=0&id=6d4ff467&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d4ff467",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VCard,VCarousel,VCarouselItem,VChip,VCol,VIcon,VImg,VOverlay,VProgressCircular,VRow})
