<template>
  <div class="_bg-default">
    <Nav />
    <div class="d-flex justify-center">
      <div class="_100vh __profile">
        <div class="_100top _60-w pa-3" v-if="person">
          <!-- INTRO -->
          <v-card class="radius-card">
            <v-img src="../../assets/img/meptest.jpeg" height="120px"></v-img>
            <v-overlay :value="loadCard" :absolute="true" color="white">
              <v-progress-circular
                color="grey"
                indeterminate
                size="35"
              ></v-progress-circular>
            </v-overlay>
            <div class="pa-3 d-flex justify-space-between">
              <v-avatar color="white" size="150" class="min-img">
                <img
                  :src="
                    person.data.photo_profile
                      ? `${env}/upload/photo_profile/${person.data.id}/${person.data.photo_profile}`
                      : avatar
                  "
                  class="img-fit"
                  alt="asd"
                />
              </v-avatar>
            </div>
            <div class="px-3">
              <h3>{{ person.data.nama_lengkap }}</h3>
              <section v-if="person.data.data_bidang">
                <p>
                  {{ person.data.data_bidang.nama }} di
                  {{ person.data.data_kota1.nama_kota }}
                </p>
              </section>
              <section v-else>
                <p>-</p>
              </section>
            </div>
            <div class="pa-2">
              <v-row>
                <v-col cols="12" md="7">
                  <v-col cols="12" md="12" class="py-0 grey--text"
                    ><v-icon>mdi-map-marker</v-icon> City</v-col
                  >
                  <v-col cols="12" md="12" class="py-0"
                    >{{ person.data.data_kota1.nama_kota }},
                    {{ person.data.data_kota1.data_provinsi.nama_provinsi }}
                  </v-col>
                </v-col>
                <v-col cols="12" md="7">
                  <v-col cols="12" md="12" class="py-0 grey--text"
                    ><v-icon>mdi-map-marker-plus</v-icon> Additional City</v-col
                  >
                  <v-col cols="12" md="12" class="py-0">
                    <div v-if="person.data.data_kota2">
                      {{ person.data.data_kota2.nama_kota }},
                      {{ person.data.data_kota2.data_provinsi.nama_provinsi }}
                    </div>
                    <div v-else>-</div>
                  </v-col>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <!-- EXPERIENCES -->
          <v-card
            class="radius-card pa-3 mt-3"
            v-if="person.data.data_pengalaman_kerja"
          >
            <div class="pa-3 d-flex justify-space-between">
              <h3>Experiences</h3>
            </div>
            <div v-if="person.data.data_pengalaman_kerja.length">
              <section
                v-for="val in person.data.data_pengalaman_kerja"
                :key="val.id"
                class="mb-2"
              >
                <div>
                  <v-icon>mdi-office-building-marker-outline</v-icon>
                  {{ val.posisi_jabatan }} di
                  {{ val.nama_perusahaan }}
                </div>
                <div class="d-flex flex-column">
                  <!-- <small class="ml-7 grey--text"
                  >Master of psycholog (Clinical psycholog)</small
                > -->
                  <small class="ml-7 grey--text"
                    >Periode : {{ val.periode }}</small
                  >
                </div>
              </section>
            </div>
            <div v-else>
              <p>-</p>
            </div>
          </v-card>
          <!-- ABOUT -->
          <v-card class="radius-card mt-3 pa-3">
            <div class="mb-2 d-flex justify-space-between">
              <h3>About</h3>
            </div>
            <div v-if="!person.data.profile_singkat">
              <p class="grey--text">-</p>
            </div>
            <article>
              <p>
                {{ person.data.profile_singkat }}
              </p>
            </article>
          </v-card>

          <!-- SKILL AND COMPETENCIES -->
          <v-card class="radius-card pa-3 mt-3">
            <div class="pa-3 d-flex justify-space-between">
              <h3>Skills and Competencies</h3>
            </div>
            <div class="d-flex flex-wrap">
              <div
                v-for="(item, idx) in person.data.skill_kompetensi"
                :key="`ex${idx}xe`"
              >
                <v-chip class="ma-2" color="orange" label outlined>
                  {{ item }}
                </v-chip>
              </div>
            </div>
          </v-card>
          <!-- RECOMENDATION -->
          <v-card class="radius-card mt-3 pa-3" v-if="recomendation.length">
            <div class="mb-2">
              <h3>Recomendation</h3>
            </div>
            <section class="_full-w mt-2" v-if="recomendation">
              <v-carousel
                v-model="slide"
                hide-delimiters
                height="auto"
                show-arrows-on-hover
              >
                <v-carousel-item v-for="item in recomendation" :key="item.id">
                  <v-card class="pa-2 white" height="100%">
                    <v-card
                      class="
                        radius-card
                        py-2
                        px-5
                        white
                        d-flex
                        flex-column
                        justify-space-between
                      "
                      height="100%"
                    >
                      <section class="black--text mb-3">
                        <v-icon color="grey">mdi-format-quote-open</v-icon>
                        <p class="ma-0">
                          {{ item.catatan }}
                        </p>
                        <div class="d-flex justify-end">
                          <v-icon color="grey">mdi-format-quote-close</v-icon>
                        </div>
                      </section>
                      <footer class="d-flex align-center justify-end">
                        <v-avatar class="mr-2">
                          <img
                            :src="
                              item.data_pemberi.photo_profile
                                ? `${env}/upload/photo_profile/${item.pemberi_rekomendasi}/${item.data_pemberi.photo_profile}`
                                : avatar
                            "
                            alt=""
                            class="img-fit"
                          />
                        </v-avatar>
                        <div class="black--text">
                          <h4 class="ma-0 pa-0 line__height">
                            {{ item.data_pemberi.nama_lengkap }}
                          </h4>
                        </div>
                      </footer>
                    </v-card>
                  </v-card>
                </v-carousel-item>
              </v-carousel>
            </section>
          </v-card>
        </div>
        <div class="_100top _10w _100vh beside__" v-if="person">
          <v-card class="radius-card pa-3 mt-3">
            <div class="pa-3 d-flex justify-space-between">
              <h3>Contact</h3>
            </div>

            <!-- <v-row> -->
            <v-col cols="12" md="12">
              <v-col cols="12" md="12" class="py-0 grey--text"
                ><v-icon>mdi-cellphone</v-icon> Phone</v-col
              >
              <v-col cols="12" md="12" class="py-0">{{
                person.data.no_hp
              }}</v-col>
            </v-col>
            <v-col cols="12" md="12">
              <v-col cols="12" md="12" class="py-0 grey--text"
                ><v-icon>mdi-email</v-icon> E-mail</v-col
              >
              <v-col cols="12" md="12" class="py-0">{{
                person.data.email
              }}</v-col>
            </v-col>

            <!-- </v-row> -->
          </v-card>
        </div>
        <div v-else-if="loading">
          <Waiting />
        </div>
        <div
          v-if="!person"
          class="_100top pa-3 d-flex flex-column align-center _full-w"
        >
          <img src="../../assets/img/404.svg" alt="404" height="200px" />
          <h3>{{ err }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Waiting from "../../components/_base/Modal/waiting.vue";
import Nav from "../../components/General/Nav.vue";

export default {
  name: "isAuthpsycholog",
  computed: {
    ...mapState({
      env: (state) => state.API_URL,
      myFeed: (state) => state.feeds.feeds,
      user_id: (state) => state.id,
      person: (state) => state.person,
      role: (state) => state.role,
      recomendation: (state) => state.psycholog.listRecomendation,
      errMsg: (state) => state.errMsg,
      avatar: (state) => state.dummy,
    }),
  },
  components: {
    Waiting,
    Nav,
    // Carousel,
  },
  data() {
    return {
      loading: false,
      loadCard: false,
      dialogIntro: false,
      dialogAbout: false,
      dialogEducate: false,
      dialogExperiences: false,
      dialogRecomend: false,
      slide: 0,
      haveMyRecomendation: true,
      err: "",
    };
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.fetch();
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      let username = this.$route.params.username;
      this.$store
        .dispatch("viewPsycholog", username)
        .then((data) => {
          this.loading = false;
          this.getRecomendation(data.data.id);
        })
        .catch((err) => {
          console.log(err);
          this.err = err;
          this.loading = false;
        });
    },

    getRecomendation() {
      const id = this.$route.params.username;
      this.$store
        .dispatch("psycholog/getRecomendationPublic", id)
        .then((data) => {
          console.log(data);
        });
    },

    closeDialog() {
      this.dialogRecomend = false;
    },
  },
};
</script>

<style scoped>
.__profile {
  display: flex;
  width: 70%;
}
._60-w {
  width: 70%;
}
._10w {
  width: 30%;
  padding-right: 20px;
}
.min-img {
  margin-top: -120px;
  border: 2px white solid;
}
.line__height {
  line-height: 0.1;
}
.beside__ {
  margin-top: 100px;
  width: 40%;
  padding-right: 20px;
}
@media (max-width: 576px) {
  .__profile {
    display: block;
    width: 100%;
  }
  .beside__ {
    margin-top: 0px;
    width: 100%;
    padding-right: 0;
  }
  ._60-w {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .__profile {
    display: block;
    width: 100%;
  }
  ._60-w {
    width: 100%;
  }
  .beside__ {
    margin-top: 0px;
    width: 100%;
    padding-right: 0;
  }
}
</style>
